import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://a36f40ddf7504f128c69b8c539504c38@o92833.ingest.sentry.io/5549293',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  })
}
