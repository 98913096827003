import './lib/sentry'
import '@hotwired/turbo-rails'
import setupTimezoneCookie from './lib/setupTimezoneCookie'
import { application } from './controllers/application'
import cookieconsent from './cookieconsent'

setupTimezoneCookie()

import TransitionController from './controllers/transition_controller.js'
application.register('transition', TransitionController)

// Non-Stimulus
document.addEventListener('turbo:load', () => {
  cookieconsent()
})
