import jstz from 'jstz'

export default function () {
  function setCookie(name, value) {
    // Expire 24 hours in future
    const expires = new Date()
    expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000)

    document.cookie = name + '=' + value + ';expires=' + expires.toUTCString()
  }

  // Update user timezone
  const timezone = jstz.determine()
  setCookie('timezone', timezone.name())
}
